<template>
  <div class="relative f-col min-h-dvh">
    <template v-if="!hideLayout">
      <vf-button
        v-if="mainContent"
        variant="primary-inverse"
        size="tiny"
        to="#main-content"
        class="absolute left-0 top-0 ml-4"
        style="z-index: 10000"
        :style="{ 'margin-top': showSkipLink ? '0.25rem' : '-100%' }"
        data-test-id="skip-to-main-content"
        @click="mainContent.focus()"
        @focus="showSkipLink = true"
        @blur="showSkipLink = false"
      >
        {{ $t.skipToMainContent }}
      </vf-button>

      <vf-header :invert />

      <div id="main-content" ref="mainContent" class="relative" tabindex="-1" style="top: -5rem" />
    </template>

    <slot />

    <template v-if="!hideLayout">
      <base-lazy-hydrate when="visible">
        <vf-footer class="mt-a" :invert />
      </base-lazy-hydrate>
    </template>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const mainContent = ref<HTMLDivElement>()
const showSkipLink = ref(false)

const hideLayout = computed(() => route.meta.hideLayout === true)
const invert = computed(() => [`/${useLocale()}`, `/${useLocale()}/`].includes(route.path))
</script>
